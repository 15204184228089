<template>
  <div style="overflow: hidden;">
    <search :hotlist="hotlist"></search>
    <!-- 轮播图 -->
    <div class="banner-box" v-if="banner?.length">
      <van-swipe class="home-main-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in banner" :key="index">
          <img :src="`${imgUrl}${item.url}`" alt="" @click="handleSkipPath(item.jumpLink)" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 优惠券 -->
    <div class="coupons-box" v-if="canGetCoupons?.length">
      <div class="content">
        <div class="header">
          <img class="title-img" src="@/assets/image/newCustomers/coupon-log.png" alt="">
          <span>{{ $t("優惠券") }}</span>
        </div>
        <div class="content-mian">
          <div :class="['content-item',{ 'useCoupon': item.isUsed }]" v-for="item in canGetCoupons" :key="item.id">
            <div class="item-left">
              <div :class="['price',{ 'price-color': item.isUsed }]">HK$<span>{{ item.price }}</span></div>
              <div :class="['tigs',{ 'tigs-color': item.isUsed }]">{{ item.name }}</div>
            </div>
            <div :class="['item-right',{ 'color': item.isUsed }]">{{ item.isGet ? item.isUsed ? $t('已使用') : $t('已領取') :
              $t('未領取') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新客专属活动 -->
    <div class="activity" v-for="item in newcusactivityModels" :key="item.id">
      <div class="title">{{ langEnv == 3 ? item.activityName : item.enActivityName }}</div>
      <div class="text">{{ langEnv == 3 ? item.activityContent : item.enActivityContent }}</div>
      <div class="content">
        <div class="content-item" v-for="it in item.activityNewCustomerProductList"
          @click.stop="handleSkipDetail(it.searchProductId)">
          <div class="top">
            <img class="prodcut-img" :src="`${getProductImageUrl(it.picturePath,400)}`" @error="handleImageError" />
          </div>
          <div class="goods-name">{{ it.brandname }}</div>
          <div class="goods-text">{{ it.name }}</div>
          <div class="footer">
            <div class="left">
              <div class="cutprice">{{ formatNumber(it.promotionPrice) }}</div>
              <div v-if="it.promotionPrice < it.price" class="costprice">{{ formatNumber(it.price) }}</div>
            </div>
            <div class="join">
              <img @click.stop="handleBuyProduct(it)" class="add-img" src="@/assets/image/newCustomers/addorder.png"
                alt="" srcset="">
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="more">查看更多 <van-icon name="arrow-down" color="#999999" size="13" /></div> -->
    </div>
    <specificationDialog v-if="isSpecificationDialog1" @handleConfirm="handleConfirm" :isAuth="isAuth"
      @handleClose="handlespecificationClose" :show="isSpecificationDialog" :tagtype="submitGoodsList.tagtype"
      :searchProductId="detailId">
    </specificationDialog>
    <promptDialog ref="promptDialogRef" :confirmText="confirmText" :content="message" @success="handleGoauthentication" />

    <div class="station"></div>
  </div>
</template>

<script>
import search from "@/components/search/index"
import { getBannerAndCouponRith, getActivityAndProductRith, takeCoupons } from "@/api/newCustomers"
import { getProduct, getProductcreateTempOrder } from "@/api/productdetails.js"
import Config from "@/utils/config.js"
import { getToken, rmoveToken } from "@/utils/token"
import { gethotWord } from "@/api/search"
import Mixins from "@/utils/mixins"
import promptDialog from "@/components/promptDialog/index.vue"

export default {
  mixins: [Mixins],
  components: {
    search,
    promptDialog
  },
  data () {
    return {
      bs: null,
      imgUrl: Config.imgUrl,
      banner: [],
      canGetCoupons: [],
      defaultImg: require('@/assets/image/e-Rith.png'),
      islogin: false,
      newcusactivityModels: [],
      isSpecificationDialog: false,
      isSpecificationDialog1: false,
      list: {},
      isAuth: {},
      submitGoodsList: {
        goods: [],
        isFromShoppingCart: false,
        isPreOrder: false,
        tagtype: 3
      },
      detailId: null,
      langEnv: null,
      hotlist: [],
      message:'',
      confirmText: this.$t('去認證'),
    }
  },
  mounted () {
    this.getData()
    this.islogin = !getToken()
    this.gethotWordData()
    this.isSpecificationDialog1 = true
    this.langEnv = window.localStorage.getItem("langEnv") || 2
  },
  methods: {
    handleGoauthentication(){
      this.$router.push('/infoPage')
    },
    async gethotWordData () {
      const res = await gethotWord()
      if (res.resultID === 1200) {
        this.hotlist = res.data.map(item => item.keyWord)
      }
    },
    handlespecificationClose () {
      this.isSpecificationDialog = false
    },
    // 立即購買
    async handleBuyProduct (row) {
      this.detailId = row.searchProductId
      const res = await getProduct({ id: row.searchProductId, tagtype: 3 })
      if (res.resultID == 1200) {
        this.list = res.data
        if (this.list.page == null) {
          this.list.page = ""
        }
        this.list.page = this.appendDomainToImgSrc(
          this.list.page,
          Config.imgUrl.replace("/upload", "")
        )
        this.list.page = this.replaceLinks(
          this.list.page,
          Config.imgUrl.replace("/upload", "")
        )
        this.isAuth = { isAuth: this.list.isAuth, text: this.list.text,btnType:2 }
        console.log('this.isAuth', this.isAuth)

        this.isSpecificationDialog = true
      }

    },
    // 下单
    async handleConfirm (data) {
      var _this = this
      this.submitGoodsList.goods = data.map(item => {
        return {
          searchProductId: item.searchProductId,
          id: item.searchProductId,
          count: item.amount,
          tagType: 3
        }
      })
      var func1 = function () {
        mmm.placeOrderList(JSON.stringify(_this.submitGoodsList.goods))
      }
      var func2 = function () {
        window.webkit.messageHandlers.placeOrderList.postMessage({ goods: JSON.stringify(_this.submitGoodsList.goods) })
      }
      var func3 = async function () {
        const res = await getProductcreateTempOrder(_this.submitGoodsList)
        if (res.resultID === 1200) {
          _this.$router.push("/order/confirm2")
        }else if(res.resultID===1340){
          _this.$refs.promptDialogRef.open()
          _this.handlespecificationClose()
          _this.message=res.message
        }
      }

      this.excFunc(func1, func2, func3)

    },
    toFixedFun (data, len) {
      return Math.floor(data * len) / len
    },
    async getData () {
      const res = await getBannerAndCouponRith({ clientType: 2 })
      if (res.isSuccess) {
        // 轮播图
        this.banner = res.data?.bannerpicurls
        // 优惠券
        this.canGetCoupons = res.data?.canGetCoupons
        const arr = res.data?.canGetCoupons.filter(item => !item.isGet).map(it => it.id)
        if (!this.islogin && arr?.length) {
          const res1 = await takeCoupons({ ids: arr })
          if (res1.resultID === 1200) {
            this.$nextTick(async () => {
              this.canGetCoupons = await this.canGetCoupons.map(item => {
                if (arr.includes(item.id)) {
                  return {
                    ...item,
                    isGet: true
                  }
                }
              })
              this.$toast(res1.message)
            })
          }
        }

      }
      const data = await getActivityAndProductRith({})
      if (data.isSuccess) {
        this.newcusactivityModels = data.data.newcusactivityModels
      }
    },
    // 跳转详情
    handleSkipDetail (data) {
      var _this = this
      var func1 = function () {
        mmm.goToTypeDetail(data, '3')
      }
      var func2 = function () {
        window.webkit.messageHandlers.goToTypeDetail.postMessage({ id: data, tagType: '3' })
      }
      var func3 = function () {
        _this.$router.push(`/product/${data}?Tagtype=3`)
      }

      this.excFunc(func1, func2, func3)
    },
    handleSkipPath (data) {
      const isFontIcon = data.substring(0, 1) == "/" ? true : false
      this.$router.push(`${!isFontIcon ? '/' : ''}${data}`)
      return

      var _this = this
      var func1 = function () {
        mmm.JumpByAppAction(data)
      }
      var func2 = function () {
        window.webkit.messageHandlers.JumpByAppAction.postMessage(data)
      }
      var func3 = function () {
        const isFontIcon = data.substring(0, 1) == "/" ? true : false
        _this.$router.push(`${!isFontIcon ? '/' : ''}${data}`)
      }
      this.excFunc(func1, func2, func3)
    },
    handleSkipMorePath (data) {
      if (data?.floorImagePath.trim() === "") {
        return false
      }
      const isFontIcon = data?.floorImagePath.substring(0, 1) == "/" ? true : false
      this.$router.push(`${!isFontIcon ? '/' : ''}${data?.floorImagePath}`)
    },
    handleImageError (e) {
      e.target.src = this.defaultImg // 只需要将此路径换成对应的默认图片路径即可
    },
    handleLogin () {
      location.href = '/login' + `?redirect=${this.$router.currentRoute.value.fullPath}`
    },
    appendDomainToImgSrc (htmlString, domain) {
      // 正则表达式匹配<img>标签的src属性
      const imgSrcPattern = /<img\s+[^>]*src\s*=\s*["']([^"']+)["'][^>]*>/g

      // 回调函数，用于替换匹配到的src值
      function replaceSrc (match, src) {
        // 检查src是否以http或https开头
        if (src.startsWith('http://') || src.startsWith('https://')) {
          return match // 如果是，则返回原始字符串
        }

        // 拼接域名和原src路径
        const newSrc = domain + src
        // 重新构造<img>标签，确保其他属性不变
        return match.replace(src, newSrc)
      }

      // 使用正则表达式替换所有匹配到的src值
      const resultHtml = htmlString.replace(imgSrcPattern, replaceSrc)

      return resultHtml
    },
    replaceLinks (text, domain) {
      // 正则表达式匹配 href 属性中的路径
      const regex = /href="(\/upload\/files\/file[^"]*)"/g
      // 替换函数
      return text.replace(regex, (match, path) => {
        // 在路径前添加域名
        return match.replace(path, `${domain}${path}`)
      })
    },
  },
  beforeDestroy () {
    this.bs.destroy()
  },
  beforeUnmount () {
    this.isSpecificationDialog1 = false
  },

}
</script>

<style lang="scss" scoped>
.banner-box {
  background: #ffffff;
  overflow: hidden;

  // 轮播图
  .home-main-swipe {
    width: 351px;
    height: 144px;
    margin: 0 auto 8px;
    overflow: hidden;
    border-radius: 12px 12px 12px 12px;

    img {

      width: 100%;
      height: 100%;
    }

    .custom-indicator {
      position: absolute;
      right: 5px;
      bottom: 5px;
      padding: 2px 5px;
      font-size: 12px;
      background: rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dioc {
        width: 6px;
        height: 6px;
        background-color: #ebedf0;
        border-radius: 100%;
        opacity: 0.3;
        transform: all 0.6s;
      }

      .isactive {
        width: 10px;
        height: 6px;
        border-radius: 2px;
        background-color: #ffffff;
        opacity: 1;
      }
    }
  }
}

.coupons-box {
  .content {
    width: 351px;
    height: 130px;
    background: linear-gradient(180deg, #FFBDBD 0%, #FFFFFF 43%), #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    margin: 16px auto;
    padding: 10px 0 16px 8px;
    box-sizing: border-box;
    overflow: hidden;

    .header {
      font-size: 0;
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .title-img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }

      span {
        font-weight: 400;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 24px;
      }
    }

    .content-mian {
      width: 351px;
      overflow: hidden;
      white-space: nowrap; //超出不换行
      overflow-x: auto; //出现水平滚动条

      &::-webkit-scrollbar {
        display: none;
      }

      .content-item {

        width: 128px;
        height: 72px;
        background: url('~@/assets/image/newCustomers/coupon.png') no-repeat;
        background-size: 100% 100%;
        margin-right: 12px;
        display: inline-flex;
        align-items: baseline;
        justify-content: space-between;
        overflow: hidden; //超出隐藏

        &.useCoupon {
          background: url('~@/assets/image/newCustomers/usecoupon.png') no-repeat;
          background-size: 100% 100%;
        }

        .item-left {
          width: 101px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          padding: 0 13px;
          box-sizing: border-box;
          overflow: hidden;

          .price {
            font-size: 8px;
            color: #DBD73F;

            &.price-color {
              color: #999999;
            }

            span {
              font-size: 33px;
              font-weight: bold;
            }
          }

          .tigs {
            white-space: wrap; //超出不换行
            font-weight: 500;
            font-size: 7px;
            color: #FFFFFF;
            line-height: 8px;

            &.tigs-color {
              color: #BDBDBD;
            }
          }
        }

        .item-right {
          width: 25px;
          font-size: 11px;
          color: #FFFFFF;
          transform: rotate(90deg);
          /* 如果需要兼容更多浏览器，可以使用以下方式 */
          -ms-transform: rotate(90deg);
          /* IE 9 */
          -webkit-transform: rotate(90deg);
          /* Chrome, Safari, Opera */
          -o-transform: rotate(90deg);
          /* Opera */
          -moz-transform: rotate(90deg);

          /* Firefox */
          &.color {
            color: #999999;
          }
        }
      }
    }
  }
}

.activity {
  width: 351px;
  margin: 16px auto;

  .title {
    font-weight: 800;
    font-size: 16px;
    color: #1A1A1A;
    line-height: 24px;
    text-align: left;
    margin-bottom: 4px;
  }

  .text {
    font-weight: 400;
    font-size: 13px;
    color: #666666;
    line-height: 20px;
    text-align: left;
    margin-bottom: 16px;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;


    .content-item {
      width: 170px;
      background: #ffffff;
      border-radius: 8px;
      overflow: hidden;
      padding-bottom: 12px;
      box-sizing: border-box;
      margin-bottom: 16px;

      .top {
        width: 170px;
        height: 170px;
        font-size: 0;

        .prodcut-img {
          width: 100%;
          height: 100%;
        }
      }

      .goods-name {
        font-weight: 400;
        font-size: 13px;
        color: #1A1A1A;
        line-height: 20px;
        text-align: left;
        padding: 12px 8px 4px 8px;
        box-sizing: border-box;
      }

      .goods-text {
        height: 40px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 13px;
        padding: 0 8px;
        box-sizing: border-box;
        color: #1A1A1A;
        line-height: 20px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 12px
      }

      .footer {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 8px;
        box-sizing: border-box;

        .left {
          .cutprice {
            font-weight: 800;
            font-size: 14px;
            color: #F82207;
            line-height: 24px;
            text-align: left;
            margin-bottom: 4px
          }

          .costprice {
            font-weight: 400;
            font-size: 12px;
            color: #B3B3B3;
            line-height: 18px;
            text-align: left;
            text-decoration: line-through;
          }
        }

        .join {
          width: 36px;
          height: 36px;
          font-size: 0;

          .add-img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

  }

  .more {
    font-weight: 400;
    font-size: 13px;
    color: #999999;
    line-height: 20px;
    text-align: center;
  }
}



.station {
  height: 100px;
}
</style>